import React from 'react';
import styles from "../../home2Banner.module.css"
import ScrollAnimation from 'react-animate-on-scroll';
import { deriveHomePathFromSpace, isNullUndefined, addDisclaimersToCmsLinks, richTextOptions } from "../../../util"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer" 
import ReactDOMServer from 'react-dom/server';
import ReactHtmlParser from "react-html-parser"

class Home2Banner extends React.Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }
    render() { 
        const {bannerSection, missionSection} = this.props.queryData || {};

        let bannerSectionRichText = documentToReactComponents(isNullUndefined(bannerSection) ? bannerSection : bannerSection.json, richTextOptions);

        let bannerSectionHtml = ReactDOMServer.renderToStaticMarkup(bannerSectionRichText);

        let missionSectionRichText = documentToReactComponents(isNullUndefined(missionSection) ? missionSection : missionSection.json, richTextOptions);

        let missionSectionHtml = ReactDOMServer.renderToStaticMarkup(missionSectionRichText);
        return ( 
            <div>
                <div className={`${styles.banner} ${styles.ukbanner}`}>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                               <div className={styles.banner_content}>
                               <ScrollAnimation animateIn="fadeInUp">
                               {ReactHtmlParser(bannerSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})}
                                </ScrollAnimation>
                            
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.homeMission}>
                    <div className="container text-center">
                    {this.props.animate ? (<ScrollAnimation animateIn="fadeInUp">
                            {ReactHtmlParser(missionSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})}
                    </ScrollAnimation>):(
                        ReactHtmlParser(missionSectionHtml, {transform: (node, index) => addDisclaimersToCmsLinks(node, index, null)})
                    )}
              
                    </div>
                   </div>
            </div>
         );
    }
}
 
export default Home2Banner;