import React from 'react';
import {graphql} from "gatsby"
import Banner from '../../../components/NonUkResidents/NonHCP/banner';
import Bio from '../../../components/NonUkResidents/NonHCP/bio';
import Vision from '../../../components/NonUkResidents/NonHCP/ourVision'; 
import SiteVersion from '../../../components/changeSites';
import Layout from '../../../components/NonUkResidents/NonHCP/layout';
import queryString from 'query-string';
import SEO from "../../../components/seo"
import { ValidateRoute } from '../../../util/route-helper';
import TemplateWrapper from "../../../components/TemplateWrapper"

export const pageQueryHomeTemplateNonUkNonHcp = graphql`
    query ($slug: String!, $spaceId: String!) {
        contentfulHomeTemplate(slug: { eq: $slug }, spaceId: {eq: $spaceId}) {
                spaceId
                slug
                bannerSection {
                    json
                }
                missionSection {
                    json
                }
                topExcerptText {
                    json
                }
                topExcerptButtonUrl
                topExcerptImage {
                    file {
                      url
                    }
                }
                bottomExcerptText {
                    json
                }
                bottomExcerptButtonUrl
                bottomExcerptImage {
                    file {
                        url
                    }
                }
        }
    }
`

const HomeTemplatePageNonUkNonHcp = (props) => {
  const queryData = props.data.contentfulHomeTemplate;
  let [animate, setAnimate] = React.useState(false);

  React.useEffect(() => {

      let {veeva} = queryString.parse(props.location.search || "");

      if(!veeva){
        setAnimate(true);
      }

  }, []);

  return ( 
    <TemplateWrapper>
        <div className={animate ? "" : "overwrite_animations"}>
    <Layout>
        <SEO title="Home | Non-UK Residents | Non-HCP" />
         <Banner queryData={queryData} animate={animate} />
         <Bio queryData={queryData} animate={animate} />
         <Vision queryData={queryData} animate={animate} />
    </Layout>
    </div>
    </TemplateWrapper>
   );
}

export default HomeTemplatePageNonUkNonHcp